import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import clsx from "clsx";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {Typography} from "@material-ui/core";
import {auth, singOut} from "../config";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Alert from "@material-ui/lab/Alert";
import {useAuthState} from 'react-firebase-hooks/auth';

import {ROUTE_LOGIN, ROUTE_NOT_FOUND} from "../routes";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import {UserContext} from "../UserContext";
import {isPermittedRoute} from "../util/route";
import {getDatabase, onValue, ref} from "firebase/database";

export const snackbarInfoList = userName => ({
    default: {
        severity: "info",
        message: "",
    },
    loginSuccess: {
        severity: "sucess",
        message: `Welcome ${userName}!`
    },
    loginError: {
        severity: "error",
        message: "Unable to login. Please try again later."
    },
    logOutSuccess: {
        severity: "info",
        message: 'You have logged out.'
    },
    logOutError: {
        severity: "error",
        message: "Unable to log out. Please try again later."
    },
    signinToContinue: {
        severity: "info",
        message: "Sign in to continue"
    },
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
        height: 'fit-content',
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        padding: theme.spacing(1),
    },
    appBar: {
        display: 'block',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
        height: 'fit-content',
    },
    // appBarShift: {
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     marginLeft: drawerWidth,
    //     transition: theme.transitions.create(['margin', 'width'], {
    //         easing: theme.transitions.easing.easeOut,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },
    // menuButton: {
    //     marginRight: theme.spacing(2),
    // },
    loginButton: {
        color: 'grey',
        fontSize: '1rem',
        textDecoration: 'bold',
        margin: theme.spacing(1),
        borderColor: 'grey',
        '&:hover': {
            color: 'white',
            borderColor: 'white',
        },
    },
    hide: {
        display: 'none',
    },
    pageTitle: {
        color: 'gray',
        fontSize: '1.5rem',
        fontWeight: 200,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        justifyContent: 'space-between',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        overflow: 'hidden',
        height: '100%',
        width: '100%',
    },
    avatarDiv: {
        margin: theme.spacing(2, 1),
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
    },
    awaitApprovalTextRoot: {
        margin: theme.spacing(1),
        color: 'gray',
        display: 'flex',
        alignItems: 'start',
        '& > *': {
            margin: theme.spacing(0, 0.5),
        }
    }
}));
const Home = ({content: {Component, pageTitle}}) => {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const db = getDatabase();
    const { pathname = '' } = useLocation();

    const [open, setOpen] = useState(false);
    const [displaySnackbar, setDisplaySnackbar] = useState(false);
    const [, setSnackbarInfo] = useState(snackbarInfoList().signinToContinue);
    const [photos, setPhotos] = useState([]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const closeSnackbar = _ => setDisplaySnackbar(false);

    const Snackbar = ({severity, message}) => {
        return <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={displaySnackbar}
                         autoHideDuration={3000} onClose={closeSnackbar}>
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    };

    useEffect(() => {
        if (photos.length === 0) {
            onValue(ref(db, "Home"), snapshot => {
                let photos = [];
                snapshot.forEach(snap => {
                    photos.push(snap.val());
                });
                setPhotos(photos);
            });
        }
    }, [photos])

    const handleClick = item => {
        if (item.onClick) {
            return item.onClick;
        }

        return () => {
            navigate(item.route);
            handleDrawerClose();
        };
    };

    if (loading) {
        return null;
    }

    if (!user) {
        return <Navigate to={ROUTE_LOGIN}/>
    }

    return (
        <UserContext.Consumer>
            {({userInfo}) => {
                if (!userInfo) {
                    return null;
                }

                if (!isPermittedRoute(userInfo, pathname)) {
                    return <Navigate to={ROUTE_NOT_FOUND} />
                }

                return (
                    <div className={classes.root}>
                        {/*<Snackbar {...snackbarInfo}/>*/}
                        {/*             <Snackbar */}
                        {/*                 anchorOrigin={{ vertical: 'top', horizontal: 'center' }} */}
                        {/*                 open={true} */}
                        {/*                 severity="info" */}
                        {/*                 message={PENDING_USER_MESSAGE} */}
                        {/*                 key="top-center-info-snackbar" */}
                        {/*             /> */}
                        <AppBar
                            position="relative"
                            className={clsx(classes.appBar)}
                            color='transparent'
                        >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    // className={clsx(classes.menuButton, open && classes.hide)}
                                    className={clsx(open && classes.hide)}
                                >
                                    <MenuIcon style={{color: 'grey'}}/>
                                </IconButton>
                                <Typography className={classes.pageTitle} noWrap>{pageTitle}</Typography>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            className={classes.drawer}
                            variant="persistent"
                            anchor="left"
                            open={open}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            // elevation={0}
                        >
                            <div className={classes.drawerHeader}>
                                {user &&
                                <div className={classes.avatarDiv}>
                                    <Avatar className={classes.avatar} src={userInfo.photoURL}
                                            atl={userInfo.displayName[0]}/>
                                    <Typography>{userInfo.displayName}</Typography>
                                </div>
                                }
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                                </IconButton>
                            </div>
                            <Divider/>
                            {
                                userInfo.enabled ?
                                    <List>
                                        {
                                            userInfo.routes && userInfo.routes.map(item => (
                                                    <ListItem button key={item.text} onClick={handleClick(item)}>
                                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                                        <ListItemText primary={item.text}/>
                                                    </ListItem>
                                                )
                                            )
                                        }
                                    </List>
                                    :
                                    <div className={classes.awaitApprovalTextRoot}>
                                        <InfoIcon/>
                                        <Typography>Your account is waiting for approval.</Typography>
                                    </div>
                            }
                            <Divider/>
                            <List>
                                <ListItem button key="sign-out-button" onClick={() => {
                                    singOut()
                                        .then(_ => {
                                            setSnackbarInfo(snackbarInfoList().logOutSuccess);
                                        }).catch(error => {
                                        setSnackbarInfo(snackbarInfoList().logOutError);
                                        console.log('Unable to log out. Error: ', error);
                                    })
                                    setOpen(false)
                                }}>
                                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                                    <ListItemText primary="Sign out"/>
                                </ListItem>
                            </List>
                        </Drawer>
                        <main className={classes.content}>
                            <Component userInfo={userInfo}/>
                        </main>
                    </div>
                )
            }}
        </UserContext.Consumer>
    );
};

export default Home;
