import moment from "moment";

const SIXTY_SECONDS_IN_MILLI = 60 * 1000;
const SIXTY_MINUTES_IN_MILLI = 60 * SIXTY_SECONDS_IN_MILLI;
const SIX_HOURS_IN_MILLI = 60 * SIXTY_MINUTES_IN_MILLI;

const DATE_TIME_FORMAT_STRING = "DD MMM YYYY, h:mm a";
const TWELVE_HRS_TIME_FORMAT_STRING = "h:mm a";
const DATE_FORMAT_STRING = "DD MMM YYYY";
const START_DATE_FORMAT_STRING = "MM-DD";
const DT_START_DATE_FORMAT_STRING = "YYYY-MM-DD";
const TWENTY_FOUR_HRS_TIME_FORMAT_STRING = "hh:mm";

export const getDisplayTimeSting = timeInMilliSeconds => {
    const delta = moment.now() - timeInMilliSeconds;

    // Seconds ago
    if (delta < SIXTY_SECONDS_IN_MILLI) {
        return "A few seconds ago"
    }

    // Minutes ago
    if (delta < SIXTY_MINUTES_IN_MILLI) {
        return `${delta / SIX_HOURS_IN_MILLI} minutes ago`;
    }

    // Hours ago
    if (delta < SIX_HOURS_IN_MILLI) {
        return `${delta / SIX_HOURS_IN_MILLI} hours ago`;
    }

    return formatTime(timeInMilliSeconds);
};

export const formatTime = timeInMilliSeconds => (moment(timeInMilliSeconds).format(DATE_TIME_FORMAT_STRING))
export const formatStartDate = timeInMilliSeconds => (moment(timeInMilliSeconds).format(START_DATE_FORMAT_STRING));
export const formatDtStart = timeInMilliSeconds => (moment(timeInMilliSeconds).format(DT_START_DATE_FORMAT_STRING));
export const formatTimeRange = (startInMilli, endInMilli) => (
    moment(startInMilli).format(DATE_TIME_FORMAT_STRING)+ ' - ' + moment(endInMilli).format(TWELVE_HRS_TIME_FORMAT_STRING)
);

export const getDateStringFromUnixTimeMillie = timeInMilliSeconds => (moment(timeInMilliSeconds).format(DATE_FORMAT_STRING))
export const formatDisplayAllDayTime = timeInMilliSeconds => (getDateStringFromUnixTimeMillie(timeInMilliSeconds));
export const get24HrTimeStringFromUnixTimeMillie = timeInMilliSeconds => (moment(timeInMilliSeconds).format(TWENTY_FOUR_HRS_TIME_FORMAT_STRING))

// TimeString format: hh:mm
export const addTimeStringToUnixTime = (timeInMilliSeconds, timeString) => {
    const [hour, minute] = timeString.split(":");
    let newTime = moment(timeInMilliSeconds);
    newTime
        .set("hour", parseInt(hour))
        .set("minute", parseInt(minute));

    return newTime.valueOf();
};

export const isValidHour = (hour) => (hour >= 0 && hour <= 23)
export const isValidMinute = (minute) => (minute >= 0 && minute <= 60)

export const isValidTimeString = (timeString) => {
    const [hour, minute] = timeString.split(":");
    return /^[0-9][0-9]:[0-9][0-9]$/.test(timeString) && isValidHour(parseInt(hour)) && isValidMinute(parseInt(minute));
};

// timeString of format hh:mm
export const isValidTimeRange = (startTimeString, endTimeString) => {
    const [startHour, startMinute] = startTimeString.split(":");
    const [endHour, endMinute] = endTimeString.split(":");

    return startHour * 60 + startMinute < endHour * 60 + endMinute;
}

export const isPastEvent = dateString => {
    const date = moment(dateString);
    return date.isBefore(moment.now());
};

export const getRecurEventCurrentYearDate = dateString => {
    const thisYear = moment().get('year');
    return `${thisYear}-${dateString}`
}
// dateString of format mm-dd
export const isRecurPastEvent = (dateString, dtStartString) => {
    const date = moment(getRecurEventCurrentYearDate(dateString));
    const startDate = moment(dtStartString);
    return startDate.isBefore(moment.now()) && date.isBefore(moment.now());
};

// dateString of format mm-dd
export const isRecurFutureEvent = (dateString, dtStartString) => {
    const date = moment(getRecurEventCurrentYearDate(dateString));
    const startDate = moment(dtStartString);
    return startDate.isAfter(moment.now()) || (startDate.isBefore(moment.now()) && date.isAfter(moment.now()));
};
