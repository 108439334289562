import React from 'react';
import LoginModal from "./LoginModal";
import {snackbarInfoList} from "./Home";
import HomePhotoGallery from "./HomePhotoGallery";
import {auth} from "../config";
import {useAuthState} from "react-firebase-hooks/auth";
import {Navigate} from "react-router-dom";
import {ROUTE_HOME} from "../routes";

const Login = () => {
    const handleClose = () => {
    };

    const [user, loading] = useAuthState(auth);

    if (loading) {
        return null;
    }

    if (user) {
        return <Navigate to={ROUTE_HOME}/>
    }

    return (
        <>
            <LoginModal handleClose={handleClose} setSnackbarInfo={snackbarInfoList().logOutSuccess}/>
            <HomePhotoGallery/>
        </>
    )
};

export default Login;
