import React, {useEffect, useState} from 'react';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {getDatabase, onValue, ref} from "firebase/database";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    card: {
        margin: theme.spacing(0, 0, 3, 0)
    },
    media: {
        height: '100%',
        paddingTop: theme.spacing(1),
    },
}));

const MemorialVideos = () => {
    const classes = useStyles();
    const [videos, setVideos] = useState([]);
    const db = getDatabase();

    useEffect(() => {
        onValue(ref(db, "Videos"), snapshot => {
            let videos = [];
            snapshot.forEach(snap => {
                videos.push(snap.val());
            });
            setVideos(videos);
        })
    }, []);

    return (
        <Container className={classes.root}>
            {
                videos.map(video => (
                    <Card className={classes.card} key={`video-card-${video.title}`}>
                        <CardContent>
                            <Typography variant="h4" componnet="h2" color="textPrimary" gutterBottom>
                                {video.title}
                            </Typography>
                            <Typography color="textSecondary">
                                {video.timestamp}
                            </Typography>
                            <Typography color="textSecondary">
                                {video.subtitle}
                            </Typography>
                            <div className={classes.media}>
                                <video width="100%" height="100%" controls>
                                    <source
                                        src={video.url}
                                        type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </CardContent>
                    </Card>
                ))
            }
        </Container>
    )
};

export default MemorialVideos;
