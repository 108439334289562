import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import clsx from "clsx";
import {Container} from "@material-ui/core";
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    gridList: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    img: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center",
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transition: 'transform .5s ease',
    },
    imgHover: {
        transform: 'scale(1.3)',
    },
    button: {
        color: 'rgba(255, 255, 255, 0.54)',
        marginRight: theme.spacing(1),
        width: '100%',
        height: '100%',
    },
    infoPanel: {
        transition: 'transform .5s ease',
        height: '100%',
    },
    buttonTextSpan: {
        color: 'white',
        position: 'absolute',
        padding: theme.spacing(1),
    },
    buttonTextSpanBorder: {
        border: '3px solid white',
        borderRadius: '5px',
    }
}));

const getGridListCols = width => (isWidthUp('sm', width) ? 1 : 2);

const StyledTooltip = withStyles({
    tooltip: {
        fontSize: "1em",
    }
})(Tooltip);

const FlowerOptionsGrid = ({flowers, onClick, isSubmitting, width}) => {
    const classes = useStyles();
    const [hoveredFlowerId, setHoveredFlowerId] = useState(undefined);

    const handleHover = id => () => {
        setHoveredFlowerId(id);
    };

    return (
        <div className={classes.root}>
            <Container>
                <GridList cellHeight={400} className={classes.gridList}>
                    {flowers.map(flower => (
                        <GridListTile key={`img-${flower.displayName}`} cols={getGridListCols(width)}
                                      onMouseEnter={handleHover(flower.id)} onMouseLeave={handleHover(undefined)}>
                            <ButtonBase className={classes.button}>
                                <span style={{backgroundImage: `url('${flower.url}')`}}
                                      className={clsx(classes.img, hoveredFlowerId === flower.id && classes.imgHover)}/>
                                {hoveredFlowerId === flower.id &&
                                <span onClick={onClick(flower.id)} className={clsx(classes.buttonTextSpan,
                                    !isSubmitting && classes.buttonTextSpanBorder)}>
                                        {!isSubmitting ? <Typography variant='h4'>Send</Typography> :
                                            <CircularProgress style={{color: "white"}}/>
                                        }
                                    </span>
                                }
                            </ButtonBase>
                            <GridListTileBar
                                title={flower.displayName}
                                subtitle={<span>{`Received: ${flower.count}`}</span>}
                                // subtitle={<span>{`last sent on: ${new Date(flower.lastUpdatedTimestamp)}`}</span>}
                                actionIcon={
                                    <StyledTooltip title={flower.info} placement="top-end">
                                        <IconButton aria-label={`info about ${flower.displayName}`}
                                                    className={classes.button}
                                                    variant="outlined">
                                            <InfoIcon/>
                                        </IconButton>
                                    </StyledTooltip>
                                }
                            >
                            </GridListTileBar>
                        </GridListTile>
                    ))}
                </GridList>
            </Container>
        </div>
    );
}

export default withWidth()(FlowerOptionsGrid);
