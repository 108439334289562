export const ROUTE_INDEX="/";
export const ROUTE_HOME="/home";
export const ROUTE_LOGIN="/login";
export const ROUTE_PHOTOS="/photos";
export const ROUTE_QUOTES="/quotes";
export const ROUTE_MEMORIAL_VIDEOS="/memorial-videos";
export const ROUTE_LEAVE_A_MESSAGE="/messages";
export const ROUTE_GIFT_FLOWERS="/gift-flowers";
export const ROUTE_CALENDAR="/calendar";
export const ROUTE_ADMIN="/admin";
export const ROUTE_NOT_FOUND="/404";
