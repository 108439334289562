import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {CardContent} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {formatTime} from "../util/time";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import CardActions from "@material-ui/core/CardActions";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import {getDatabase, onValue, ref, orderByChild, query, runTransaction, remove} from "firebase/database";
import {v4 as uuidv4} from 'uuid';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    messageInput: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row-reverse',
        '& > *': {
            margin: theme.spacing(1, 0),
        }
    },
    messageList: {
        width: '100%',
        '& > *': {
            margin: theme.spacing(1, 0),
        }
    },
    avatar: {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    smallAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    editActionButtons: {
        float: 'right',
        margin: theme.spacing(1, 0),
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
    },
    commentsRoot: {
        '& > *': {
            margin: theme.spacing(1, 0)
        }
    }
}));

const ITEM_HEIGHT = 48;
const PAGE_ITEM_COUNT = 5;

const Messages = ({userInfo}) => {
    const classes = useStyles();

    const [messageList, setMessageList] = useState([]);
    const [displayMessageList, setDisplayMessageList] = useState([]);
    const [displaySnackbar, setDisplaySnackbar] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shouldRefreshMessageList, setShouldRefreshMessageList] = useState(true);
    const [newMessage, setNewMessage] = useState(undefined);
    const [snackbarMessage, setSnackbarMessage] = useState(undefined);

    const [anchorEl, setAnchorEl] = useState(null);
    const [clickedCommentId, setClickedCommentId] = useState(undefined);
    const [commentingMessage, setCommentingMessage] = useState(undefined);
    const [commentingMessageId, setCommentingMessageId] = useState(undefined);
    const [editingMessageId, setEditingMessageId] = useState(undefined);
    const [editingNewMessage, setEditingNewMessage] = useState(undefined);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const [page, setPage] = useState(1);
    const db = getDatabase();

    useEffect(() => {
        if (shouldRefreshMessageList) {
            onValue(query(ref(db, "/Messages"), orderByChild('timestamp'))
                , snapshot => {
                    const newPageCount = snapshot.numChildren % PAGE_ITEM_COUNT ?
                        Math.floor(snapshot.numChildren / PAGE_ITEM_COUNT) + 1 :
                        snapshot.numChildren / PAGE_ITEM_COUNT;
                    setNumberOfPages(newPageCount);
                    (snapshot.numChildren);
                    let updatedMessages = [];
                    snapshot.forEach(snap => {
                        updatedMessages.push(snap.val());
                    });
                    updatedMessages.reverse();
                    setMessageList(updatedMessages);
                    setDisplayMessageList(updatedMessages.slice(0, PAGE_ITEM_COUNT));
                })
            setShouldRefreshMessageList(false);
        }

    }, [shouldRefreshMessageList, page, messageList]);

    useEffect(() => {
        setDisplayMessageList(messageList.slice((page - 1) * PAGE_ITEM_COUNT, page * PAGE_ITEM_COUNT));
    }, [messageList, page, shouldRefreshMessageList])

    const handleClickSettings = id => event => {
        setAnchorEl(event.currentTarget)
        setClickedCommentId(id);
    };

    const handleCloseSettings = _ => {
        setAnchorEl(null);
        setClickedCommentId(undefined);
    };

    const handlePostComment = (message, messageId) => _ => {
        setIsSubmitting(true);

        if (!message || message.length === 0) {
            setDisplaySnackbar(true);
            setIsSuccessful(false);
            setSnackbarMessage("Cannot post empty message. Please try again");
            setIsSubmitting(false);
            return;
        }

        const newPostKey = uuidv4();
        runTransaction(ref(db, `/Messages/${messageId}/comments`), existingComments => {
            let comments = existingComments || [];
            const newComment = {
                id: newPostKey,
                displayName: userInfo.displayName,
                photoURL: userInfo.photoURL,
                email: userInfo.email,
                text: message,
                author: userInfo.uid,
                timestamp: moment.now().valueOf(),
            }
            comments[newComment.id] = newComment;
            return comments;
        }).then(_ => {
            setDisplaySnackbar(true);
            setIsSuccessful(true);
            setSnackbarMessage("Comment added");
            setShouldRefreshMessageList(true);
            setCommentingMessageId(undefined);
            setCommentingMessage(undefined);
        }).catch(error => {
            console.log(error.message);
            setIsSuccessful(false);
            setDisplaySnackbar(true);
            setSnackbarMessage("An error occurred. Please try again later.");
        })
        setIsSubmitting(false);
    };

    const handlePostMessage = (message, messageId) => _ => {
        if (!message || message.length === 0) {
            setDisplaySnackbar(true);
            setIsSuccessful(false);
            setSnackbarMessage("Cannot post empty message. Please try again");
            setIsSubmitting(false);
            return;
        }

        const newPostKey = messageId ? messageId : uuidv4();
        const updates = {
            id: newPostKey,
            displayName: userInfo.displayName,
            photoURL: userInfo.photoURL,
            email: userInfo.email,
            text: message,
            author: userInfo.uid,
            timestamp: moment.now().valueOf(),
        };

        setIsSubmitting(true);

        runTransaction(ref(db, `/Messages/${newPostKey}`), existingMessage => {
            if (existingMessage) {
                return {
                    ...existingMessage,
                    ...updates
                }
            } else {
                return updates;
            }
        }).then(_ => {
            setDisplaySnackbar(true);
            setIsSuccessful(true);
            setSnackbarMessage(messageId ? "Message updated" : "Message posted");
            setShouldRefreshMessageList(true);
            setNewMessage("");
        })
            .catch(error => {
                console.log(error.message);
                setIsSuccessful(false);
                setDisplaySnackbar(true);
                setSnackbarMessage("An error occurred. Please try again later.");
            });

        if (messageId) {
            setEditingNewMessage(undefined);
            setEditingMessageId(undefined);
        }
        setIsSubmitting(false);
    };

    const handleNewMessageChange = event => {
        setNewMessage(event.target.value);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDisplaySnackbar(false);
    };

    const handleDelete = (id, parentId) => _ => {
        const path = parentId ? `/Messages/${parentId}/comments/${id}` : `/Messages/${id}`;
        remove(ref(db, path))
            .then(_ => {
                setDisplaySnackbar(true);
                setIsSuccessful(true);
                setSnackbarMessage("Message deleted");
                setShouldRefreshMessageList(true);
            })
            .catch(error => {
                console.log(error.message);
                setIsSuccessful(false);
                setDisplaySnackbar(true);
                setSnackbarMessage("Failed to delete message. Please try again later.");
            });

        setAnchorEl(null);
        setClickedCommentId(undefined);
        setNewMessage("");
    };

    const handleAddComment = id => _ => {
        setCommentingMessageId(id);
    };

    const handleCancelComment = _ => {
        setClickedCommentId(undefined);
    }

    const handleOpenEditor = id => _ => {
        setEditingMessageId(id);
        setAnchorEl(null);
    };

    const handleCancelEdit = _ => {
        setEditingMessageId(undefined);
        setEditingNewMessage(undefined);
    };

    const handlePaginationChange = (event, value) => {
        setPage(value);
    };

    return (
        <Grid container className={classes.root}>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={displaySnackbar}
                      autoHideDuration={3000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={isSuccessful ? "success" : "error"}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Grid item className={classes.messageInput}>
                <TextField
                    id="outlined-multiline-static"
                    label="Leave a message"
                    multiline
                    fullWidth
                    rows={4}
                    placeholder="Enter message..."
                    variant="outlined"
                    value={newMessage}
                    onChange={handleNewMessageChange}
                />
                <Button variant="contained" size="large" color="primary" onClick={handlePostMessage(newMessage)}
                        disabled={isSubmitting}>Post</Button>
            </Grid>
            <Divider variant="middle"/>
            <Grid item className={classes.messageList}>
                {displayMessageList.map((message, index) => (
                    <Card key={`message-${index}`}>
                        <CardHeader
                            avatar={<Avatar variant="square" src={message.photoURL} className={classes.avatar}/>}
                            {...(message.author === userInfo.uid || userInfo.role === 'admin') && {
                                action: (
                                    <>
                                        <IconButton aria-label="settings"
                                                    onClick={handleClickSettings(message.id)}>
                                            <MoreVertIcon/>
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl) && clickedCommentId === message.id}
                                            onClose={handleCloseSettings}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            <MenuItem key="delete-select" onClick={handleDelete(message.id)}>
                                                Delete
                                            </MenuItem>

                                            <MenuItem key="edit-select" onClick={handleOpenEditor(message.id)}>
                                                Edit
                                            </MenuItem>
                                        </Menu>
                                    </>
                                )
                            }
                            }
                            title={message.displayName}
                            titleTypographyProps={{className: "MuiTypography-h5"}}
                            subheader={formatTime(message.timestamp)}
                            subheaderTypographyProps={{className: "MuiTypography-body1"}}
                        />
                        <CardContent>
                            {editingMessageId === message.id ?
                                <>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Editing message"
                                        multiline
                                        fullWidth
                                        rows={3}
                                        placeholder="Enter new message..."
                                        variant="outlined"
                                        defaultValue={message.text}
                                        value={editingNewMessage}
                                        onChange={e => setEditingNewMessage(e.target.value)}
                                    />
                                    <div className={classes.editActionButtons}>
                                        <Button variant="contained" color="primary"
                                                onClick={handlePostMessage(editingNewMessage, editingMessageId)}
                                                disabled={isSubmitting || !editingNewMessage}>Submit</Button>
                                        <Button onClick={handleCancelEdit}>Cancel</Button>
                                    </div>
                                </> :
                                <Typography variant="body1" color="textSecondary" component="p">
                                    {message.text}
                                </Typography>
                            }
                            {
                                message.comments &&
                                <>
                                    <Divider/>
                                    <div className={classes.commentsRoot}>
                                        {Object.values(message.comments).map(comment => {
                                            return (
                                                <Card key={comment.id}>
                                                    <CardHeader
                                                        avatar={<Avatar variant="square" src={comment.photoURL}
                                                                        className={classes.smallAvatar}/>}
                                                        title={comment.displayName}
                                                        subheader={formatTime(comment.timestamp)}
                                                        {...(comment.author === userInfo.uid || userInfo.role === 'admin') && {
                                                            action: (
                                                                <>
                                                                    <IconButton aria-label="settings"
                                                                                onClick={handleClickSettings(comment.id)}>
                                                                        <MoreVertIcon/>
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="long-menu"
                                                                        anchorEl={anchorEl}
                                                                        keepMounted
                                                                        open={Boolean(anchorEl) && clickedCommentId === comment.id}
                                                                        onClose={handleCloseSettings}
                                                                        PaperProps={{
                                                                            style: {
                                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                                width: '20ch',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem key="delete-select"
                                                                                  onClick={handleDelete(comment.id, message.id)}>
                                                                            Delete
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </>
                                                            )
                                                        }}
                                                    />
                                                    <CardContent>
                                                        <Typography variant="body1" color="textSecondary"
                                                                    component="p">
                                                            {comment.text}
                                                        </Typography>

                                                    </CardContent>
                                                </Card>
                                            )
                                        })
                                        }
                                    </div>
                                </>
                            }
                        </CardContent>
                        <CardActions>
                            <Button startIcon={<AddIcon/>} onClick={handleAddComment(message.id)}>
                                Comment
                            </Button>
                        </CardActions>
                        {commentingMessageId === message.id &&
                            <Card style={{paddingLeft: 30}}>
                                <CardHeader
                                    avatar={<Avatar variant="square" src={userInfo.photoURL}
                                                    className={classes.smallAvatar}/>}
                                    title={userInfo.displayName}
                                />
                                <CardContent>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Add comment"
                                        multiline
                                        fullWidth
                                        placeholder="Enter new comment..."
                                        variant="outlined"
                                        value={commentingMessage}
                                        onChange={e => setCommentingMessage(e.target.value)}
                                    />
                                    <div className={classes.editActionButtons}>
                                        <Button variant="contained" color="primary"
                                                onClick={handlePostComment(commentingMessage, commentingMessageId)}
                                                disabled={isSubmitting || !commentingMessage}>Submit</Button>
                                        <Button onClick={handleCancelComment}>Cancel</Button>
                                    </div>
                                </CardContent>
                            </Card>}
                    </Card>
                ))
                }
                <div className={classes.pagination}>
                    <Pagination count={numberOfPages} shape="rounded" page={page} onChange={handlePaginationChange}/>
                </div>
            </Grid>
        </Grid>
    )
};

export default Messages;
