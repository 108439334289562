import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import interactionPlugin from '@fullcalendar/interaction';
import {Container, isWidthDown, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
    addTimeStringToUnixTime, formatDisplayAllDayTime, formatDtStart, formatStartDate, formatTime,
    getDateStringFromUnixTimeMillie, getRecurEventCurrentYearDate,
    isPastEvent, isRecurPastEvent, isValidTimeRange, isValidTimeString
} from "../util/time";
import moment from "moment";
import withWidth from '@material-ui/core/withWidth';
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {getDatabase, onValue, orderByChild, query, ref, runTransaction, set} from "firebase/database";
import {v4 as uuidv4} from 'uuid';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        }
    },
    sideBar: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    calendarContainer: {
        width: '80%'
    },
    popover: {
        display: 'block',
        maxHeight: '500px',
        overFlowY: 'auto',
    },
    closeButton: {
        float: 'right',
    },
    button: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(2),
    },
    createEventForm: {
        margin: theme.spacing(1, 0),
        display: 'flex',
        justifyContent: 'center',

        '& > *': {
            display: 'block',
            margin: theme.spacing(1),
        }
    }
}));

const EventCalendar = ({width, userInfo}) => {
    const classes = useStyles();
    const isSmallScreen = isWidthDown("xs", width);

    const [futureEvents, setFutureEvents] = useState([]);
    const [futureFiveEvents, setFutureFiveEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [pastFiveEvents, setPastFiveEvents] = useState([]);
    const [shouldRefreshEvents, setShouldRefreshEvents] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateSelectedInUnix, setDateSelectedInUnix] = useState(0);
    const [startTimeString, setStartTimeString] = useState("00:00");
    const [endTimeString, setEndTimeString] = useState("00:00");
    const [title, setTitle] = useState(undefined);
    const [isAllDayEvent, setIsAllDayEvent] = useState(false);
    const [isRecur, setIsRecur] = useState(false);
    const [recurFrequency, setRecurFrequency] = useState('weekly');
    const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
    const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [displaySnackbar, setDisplaySnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [startTimeHelperText, setStartTimeHelperText] = useState(undefined);
    const [endTimeHelperText, setEndTimeHelperText] = useState(undefined);

    const open = Boolean(anchorEl);
    const db = getDatabase();

    useEffect(() => {
        if (shouldRefreshEvents) {
            onValue(query(ref(db, "Events"), orderByChild('start')), snapshot => {
                    let pastEvents = [];
                    let futureEvents = [];
                    snapshot.forEach(snap => {
                        const data = snap.val();
                        if (!data.isRecur) {
                            if (isPastEvent(data.start)) {
                                pastEvents.push(data);
                            } else {
                                futureEvents.push(data);
                            }
                        } else {
                            if (isRecurPastEvent(data.startDate, data.rrule.dtstart)) {
                                pastEvents.push({start: getRecurEventCurrentYearDate(data.startDate), ...data});
                            } else {
                                futureEvents.push({start: getRecurEventCurrentYearDate(data.startDate), ...data})
                            }
                        }
                    })
                    setPastEvents(pastEvents);
                    setPastFiveEvents(pastEvents.slice(0, 4));
                    setFutureEvents(futureEvents);
                    setFutureFiveEvents(futureEvents.slice(0, 4));
                });
            setShouldRefreshEvents(false);
        }
    }, [shouldRefreshEvents]);

    useEffect(() => {
        setStartTimeString("00:00");
        setEndTimeString("00:00");
        setTitle(undefined);
        setIsAllDayEvent(false);
        setIsInvalidStartTime(false);
        setIsInvalidEndTime(false);
        setStartTimeHelperText(undefined);
        setEndTimeHelperText(undefined);
        setIsRecur(false);
    }, [dateSelectedInUnix, shouldRefreshEvents])

    const handleCloseCalendarButtonClick = _ => {
        anchorEl.style.backgroundColor = 'unset';
        setAnchorEl(null);
        setDateSelectedInUnix(0);
    };

    const handleOpenAddEventButton = event => {
        setAnchorEl(event.currentTarget);
        setDateSelectedInUnix(moment.now());
    }

    const handleDateClick = event => {
        setAnchorEl(event.dayEl);
        setDateSelectedInUnix(moment(event.date).valueOf());
        event.dayEl.style.backgroundColor = '#d7f0f7';
    };

    const handleSubmit = _ => {
        const newEventKey = uuidv4();
        const updates = {
            id: newEventKey,
            createdBy: userInfo.displayName,
            createdOn: moment.now(),
            title,
            start: isAllDayEvent ? dateSelectedInUnix : addTimeStringToUnixTime(dateSelectedInUnix, startTimeString),
            allDay: isAllDayEvent,
            ...!isAllDayEvent && {end: addTimeStringToUnixTime(dateSelectedInUnix, endTimeString)},
            ...isRecur && {
                isRecur,
                color: '#ffee9e',
                textColor: '#757575',
                startDate: formatStartDate(dateSelectedInUnix),
                rrule: {
                    freq: recurFrequency,
                    dtstart: formatDtStart(dateSelectedInUnix),
                }
            }
        };

        runTransaction(ref(db, `/Events/${newEventKey}`), existingEvent => {
            if (existingEvent) {
                return {
                    ...existingEvent,
                    ...updates
                }
            } else {
                return updates;
            }
        })
            .then(_ => {
                setDisplaySnackbar(true);
                setIsSuccessful(true);
                setSnackbarMessage("Event successfully created");
                setShouldRefreshEvents(true);
                handleCloseCalendarButtonClick();
            })
            .catch(error => {
                console.log(error.message);
                setIsSuccessful(false);
                setSnackbarMessage("An error occurred. Please try again later.");
            });
    };

    const handleTimeChange = isStartTime => event => {
        const newTime = event.target.value;

        if (isStartTime) {
            setStartTimeString(newTime);
            if (!isValidTimeString(newTime)) {
                setIsInvalidStartTime(true);
                setStartTimeHelperText("Invalid. Use format hh:mm");
            } else if (!isValidTimeRange(newTime, startTimeString)) {
                setIsInvalidStartTime(true);
                setStartTimeHelperText("Invalid time range");
            } else {
                setIsInvalidStartTime(false);
                setIsInvalidEndTime(false);
                setStartTimeHelperText(undefined);
                setEndTimeHelperText(undefined);
            }
        } else {
            setEndTimeString(newTime);
            if (!isValidTimeString(newTime)) {
                setIsInvalidEndTime(true);
                setEndTimeHelperText("Invalid. Use format hh:mm");
            } else if (!isValidTimeRange(startTimeString, newTime)) {
                setIsInvalidEndTime(true);
                setEndTimeHelperText("Invalid time range");
            } else {
                setIsInvalidStartTime(false);
                setIsInvalidEndTime(false);
                setStartTimeHelperText(undefined);
                setEndTimeHelperText(undefined);
            }
        }
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDisplaySnackbar(false);
    };

    return (
        <Container className={classes.root}>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={displaySnackbar}
                      autoHideDuration={3000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={isSuccessful ? "success" : "error"}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Grid item className={classes.sideBar}>
                <Typography variant="body1">Past events</Typography>
                <List>
                    {pastFiveEvents.map(event => (
                        <ListItem key={event.title}>
                            <ListItemText
                                primary={event.title}
                                secondary={event.allDay ? formatDisplayAllDayTime(event.start) : formatTime(event.start)}
                            />
                        </ListItem>
                    ))}
                </List>
                <Typography variant="body1">Upcoming events</Typography>
                <List>
                    {futureFiveEvents.map(event => (
                        <ListItem key={event.title}>
                            <ListItemText
                                primary={event.title}
                                secondary={event.allDay ? formatDisplayAllDayTime(event.start) : formatTime(event.start)}
                            />
                        </ListItem>
                    ))}
                </List>
            </Grid>
            {!isSmallScreen && <Grid item className={classes.calendarContainer}>
                <FullCalendar
                    plugins={[dayGridPlugin, scrollGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={[...pastEvents, ...futureEvents]}
                    dayMinWidth={80}
                    schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                    dateClick={handleDateClick}
                />
            </Grid>}
            {isSmallScreen && <Fab color="primary" aria-label="calendar" className={classes.button}
                                   onClick={handleOpenAddEventButton}><AddIcon/></Fab>}
            <Popover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                anchorEl={anchorEl}
                className={classes.popover}
            >
                <IconButton aria-label="close" className={classes.closeButton}
                            onClick={handleCloseCalendarButtonClick}>
                    <CloseIcon/>
                </IconButton>
                <Grid container className={classes.createEventForm}>
                    <Grid item xs={10}>
                        <Typography variant="h5" component="p">
                            New event
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {isSmallScreen ? <Calendar style={{boarder: 'none'}}
                                                   value={moment(dateSelectedInUnix).toDate()}
                                                   onChange={date => {
                                                       // console.log('date: ', date);
                                                       setDateSelectedInUnix(date)
                                                   }}/> :
                            <Typography variant="caption" component="p">
                                {getDateStringFromUnixTimeMillie(dateSelectedInUnix)}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={10}>
                        <TextField required
                                   id="standard-required"
                                   onChange={e => setTitle(e.target.value)}
                                   value={title}
                                   label="Title"
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextField id="start-time-field"
                                   value={startTimeString}
                                   onChange={handleTimeChange(true)}
                                   error={isInvalidStartTime}
                                   helperText={startTimeHelperText}
                                   disabled={isAllDayEvent}
                                   label="Start time"
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextField id="end-time-field"
                                   onChange={handleTimeChange(false)}
                                   value={endTimeString}
                                   error={isInvalidEndTime}
                                   helperText={endTimeHelperText}
                                   disabled={isAllDayEvent}
                                   label="End time"
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <FormControlLabel control={
                            <Checkbox color="primary" inputProps={{'aria-label': 'is all day event'}}/>}
                                          label="All day" checked={isAllDayEvent} onChange={() => {
                            setIsAllDayEvent(!isAllDayEvent);
                        }}/>
                    </Grid>
                    <Grid item xs={10}>
                        <FormControlLabel control={
                            <Checkbox color="primary" inputProps={{'aria-label': 'is all day event'}}/>}
                                          label="Reoccurrence" checked={isRecur}
                                          onChange={() => {
                                              setIsRecur(!isRecur);
                                          }}/>
                    </Grid>
                    {isRecur &&
                    <Grid item xs={10}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="frequency-select-label">Frequency</InputLabel>
                            <Select
                                labelId="frequency-select-label"
                                id="frequency-select"
                                value={recurFrequency}
                                onChange={e => setRecurFrequency(e.target.value)}
                            >
                                <MenuItem value='yearly'>Yearly</MenuItem>
                                <MenuItem value='monthly'>Monthly</MenuItem>
                                <MenuItem value='weekly'>Weekly</MenuItem>
                                <MenuItem value='daily'>Daily</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    }
                    <Grid item xs={10}>
                        <Button disabled={(!isAllDayEvent && (isInvalidStartTime || isInvalidEndTime)) || !title}
                                onClick={handleSubmit}>Submit</Button>
                    </Grid>
                </Grid>
            </Popover>
        </Container>
    );
};

export default withWidth()(EventCalendar);
