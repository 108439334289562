import React, {useEffect, useState} from 'react';
import {Container, IconButton, Typography} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from '@material-ui/icons/Delete';
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "./Alert";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import {getDatabase, onValue, ref, runTransaction, remove} from "firebase/database";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            padding: theme.spacing(1, 0, 0, 0),
        }
    },
    table: {
        minWidth: 350,
    },
}));

const Admin = () => {
    const classes = useStyles();
    const [pendingUsers, setPendingUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [shouldRefreshUsers, setShouldRefreshUsers] = useState(true);
    const [displaySnackbar, setDisplaySnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [isSuccessful, setIsSuccessful] = useState(false);
    const db = getDatabase();

    useEffect(() => {
        if (shouldRefreshUsers) {
            onValue(ref(db, "/Users"), snapshot => {
                    let pendingUsers = [];
                    let users = [];
                    snapshot.forEach(snap => {
                        if (!snap.val().enabled) {
                            pendingUsers.push(snap.val());
                        } else {
                            users.push(snap.val());
                        }
                    })
                    setPendingUsers(pendingUsers);
                    setUsers(users);
                });
            setShouldRefreshUsers(false);
        }
    }, [pendingUsers, users, shouldRefreshUsers]);

    const handleToggleUserEnabled = uid => ({target: {checked}}) => {
        runTransaction(ref(db, `/Users/${uid}/enabled`), enable => !enable)
            .then(_ => {
                setSnackbarMessage(`User "${uid}" ${checked ? "enabled" : "disabled"}`);
                setDisplaySnackbar(true);
                setIsSuccessful(true);
                setShouldRefreshUsers(true);
            })
            .catch(error => {
                console.log(error.message);
                setSnackbarMessage("Unable to change user status!");
                setIsSuccessful(false);
            });
    };

    const handleRoleChange = uid => ({target: {value}}) => {
        runTransaction(ref(db, `/Users/${uid}/role`), () => value)
            .then(_ => {
                setSnackbarMessage(`Assigned role "${value}" to UID "${uid}"`)
                setDisplaySnackbar(true);
                setIsSuccessful(true);
                setShouldRefreshUsers(true);
            })
            .catch(error => {
                console.log(error.message);
                setSnackbarMessage(`Failed to assigned role "${value}"`)
                setIsSuccessful(false);
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDisplaySnackbar(false);
    };

    const handleDeleteUser = uid => _ => {
        remove(ref(db, `/Users/${uid}`))
            .then(_ => {
                setDisplaySnackbar(true);
                setIsSuccessful(true);
                setSnackbarMessage(`User ${uid} deleted`);
                setShouldRefreshUsers(true);
            })
            .catch(error => {
                console.log(error.message);
                setIsSuccessful(false);
                setSnackbarMessage("Failed to delete user. Please try again later.");
            });
    };
    return (
        <Container className={classes.root}>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={displaySnackbar}
                      autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={isSuccessful ? "success" : "error"}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h5">Pending requests</Typography>
            <TableContainer component={Paper} style={{overflow: 'auto'}}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><NotInterestedIcon/></TableCell>
                            <TableCell>Uid</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pendingUsers.map(pendingUser => {
                                return (
                                    <TableRow key={pendingUser.uid}>
                                        <TableCell padding="checkbox">
                                            <IconButton aria-label="delete" onClick={handleDeleteUser(pendingUser.uid)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {pendingUser.uid}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {pendingUser.displayName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {pendingUser.email}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Switch
                                                checked={pendingUser.enabled}
                                                onChange={handleToggleUserEnabled(pendingUser.uid)}
                                                color="primary"
                                                name="Enabled-request-pending-user"
                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={pendingUser.role}
                                                    onChange={handleRoleChange(pendingUser.uid)}
                                                >
                                                    <MenuItem value='family'>Family</MenuItem>
                                                    <MenuItem value='guest'>Guest</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h5">Users</Typography>
            <TableContainer component={Paper} style={{overflow: 'auto'}}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><NotInterestedIcon/></TableCell>
                            <TableCell>Uid</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            users.map(user => {
                                return (
                                    <TableRow key={user.uid}>
                                        <TableCell padding="checkbox">
                                            <IconButton aria-label="delete" onClick={handleDeleteUser(user.uid)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {user.uid}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {user.displayName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {user.email}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Switch
                                                checked={user.enabled}
                                                onChange={handleToggleUserEnabled(user.uid)}
                                                color="primary"
                                                name="enabled-request-user"
                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={user.role}
                                                    onChange={handleRoleChange(user.uid)}
                                                >
                                                    <MenuItem value='family'>Family</MenuItem>
                                                    <MenuItem value='guest'>Guest</MenuItem>
                                                    <MenuItem value='admin' disabled={true}>Admin</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
};

export default Admin;
