import React, {useEffect, useState} from 'react';
import './App.css';
import Landing from "./components/Landing";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Routes
} from "react-router-dom";
import {
    ROUTE_ADMIN,
    ROUTE_CALENDAR,
    ROUTE_GIFT_FLOWERS,
    ROUTE_HOME,
    ROUTE_INDEX,
    ROUTE_LEAVE_A_MESSAGE,
    ROUTE_LOGIN,
    ROUTE_MEMORIAL_VIDEOS, ROUTE_NOT_FOUND,
    ROUTE_PHOTOS,
    ROUTE_QUOTES
} from "./routes";
import Login from "./components/Login";
import Home from "./components/Home";
import HomePhotoGallery from "./components/HomePhotoGallery";
import Photos from "./components/Photos";
import Quotes from "./components/Quotes";
import MemorialVideos from "./components/MemorialVideos";
import Messages from "./components/Messages";
import GiftFlowers from "./components/GiftFlowers";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./config";
import Calendar from "./components/EventCalendar";
import Admin from "./components/Admin";
import HomeIcon from "@material-ui/icons/Home";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import MovieIcon from "@material-ui/icons/Movie";
import MessageIcon from "@material-ui/icons/Message";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import EventIcon from "@material-ui/icons/Event";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import {UserContext} from "./UserContext";
import NotFound from "./components/NotFound";
import {getDatabase, ref, onValue, get, child} from "firebase/database";

const homePhotoGalleryContent = user => ({
    Component: HomePhotoGallery,
    pageTitle: user ? `Welcome, ${user.displayName}` : '',
});

const photosContent = {
    Component: Photos,
    pageTitle: 'Photos',
};

const quotesContent = {
    Component: Quotes,
    pageTitle: 'Quotes',
};
const memorialVideosContent = {
    Component: MemorialVideos,
    pageTitle: 'Memorial videos',
};

const messagesContent = {
    Component: Messages,
    pageTitle: 'Messages',
};
const giftFlowersContent = {
    Component: GiftFlowers,
    pageTitle: 'Gift flowers',
};

const calendarContent = {
    Component: Calendar,
    pageTitle: 'Calendar',
};

const adminContent = {
    Component: Admin,
    pageTitle: 'Administration',
};

const App = () => {

    const [user] = useAuthState(auth);
    const [userInfo, setUserInfo] = useState(undefined);

    useEffect(() => {
        const menuItemList = [
            {
                id: 'home',
                text: 'Home',
                icon: <HomeIcon/>,
                route: ROUTE_HOME,
                index: 0,
            },
            {
                id: 'photos',
                text: 'Photos',
                icon: <PhotoLibraryIcon/>,
                route: ROUTE_PHOTOS,
                index: 1,
            },
            {
                id: 'quotes',
                text: 'Quotes',
                icon: <FormatQuoteIcon/>,
                route: ROUTE_QUOTES,
                index: 2,
            },
            {
                id: 'digitalObituary',
                text: 'Digital obituary',
                icon: <CardGiftcardIcon/>,
                onClick: () => {
                    const db = getDatabase();
                    get(child(ref(db), "/Obituary/url")).then((snapshot) => {
                        if (snapshot.exists()) {
                            const url = snapshot.val();
                            window.open(url)
                        }
                    });
                },
                index: 3,
            },
            {
                id: 'memorialVideos',
                text: 'Memorial videos',
                icon: <MovieIcon/>,
                route: ROUTE_MEMORIAL_VIDEOS,
                index: 4,
            },
            {
                id: 'messages',
                text: 'Leave a message',
                icon: <MessageIcon/>,
                route: ROUTE_LEAVE_A_MESSAGE,
                index: 5,
            },
            {
                id: 'giftFlowers',
                text: 'Gift flowers',
                icon: <FilterVintageIcon/>,
                route: ROUTE_GIFT_FLOWERS,
                index: 6,
            },
            {
                id: 'calendar',
                text: 'Calendar',
                icon: <EventIcon/>,
                route: ROUTE_CALENDAR,
                index: 7,
            },
            {
                id: 'admin',
                text: 'Admin',
                icon: <SupervisorAccountIcon/>,
                route: ROUTE_ADMIN,
                index: 8,
            },
        ];

        const findMenuItemById = id => (menuItemList.find(menuItem => menuItem.id === id));
        if (user) {
            const db = getDatabase();
            onValue(ref(db, `/Users/${user.uid}`), snapshot => {
                let userInfo = {};
                snapshot.forEach(snap => {
                    if (snap.key === "routes") {
                        let permittedRoutes = [];
                        const entries = Object.entries(snap.val());
                        entries.forEach(([route, isEnabled]) => {
                            if (isEnabled) {
                                const menuItem = findMenuItemById(route);
                                permittedRoutes[menuItem.index] = menuItem
                            }
                        })
                        userInfo[snap.key] = permittedRoutes.filter(route => (route));
                    } else {
                        userInfo[snap.key] = snap.val();
                    }
                });
                setUserInfo(userInfo);
            });
        }
    }, [user]);

    const Root = () => (
        <UserContext.Provider value={{userInfo}}>
            <Routes>
                <Route path={ROUTE_INDEX} exact element={<Landing/>}/>
                <Route path={ROUTE_LOGIN} element={<Login/>}/>
                <Route path={ROUTE_NOT_FOUND} element={<NotFound/>}/>
                <Route path={ROUTE_HOME} element={<Home content={homePhotoGalleryContent(user)}/>}/>
                <Route path={ROUTE_PHOTOS} element={<Home content={photosContent}/>} />
                <Route path={ROUTE_QUOTES} element={<Home content={quotesContent}/>}/>
                <Route path={ROUTE_MEMORIAL_VIDEOS} element={ <Home content={memorialVideosContent}/>} />
                <Route path={ROUTE_LEAVE_A_MESSAGE} element={<Home content={messagesContent}/>} />
                <Route path={ROUTE_GIFT_FLOWERS} element={<Home content={giftFlowersContent}/>} />
                <Route path={ROUTE_CALENDAR} element={<Home content={calendarContent}/>} />
                <Route path={ROUTE_ADMIN} element={<Home content={adminContent}/>} />
                <Route element={<NotFound/>}/>
            </Routes>
        </UserContext.Provider>);

    const router = createBrowserRouter([
        {path: "*", Component: Root},
    ]);

    return <RouterProvider router={router}/>;
};

export default App;
