import React, {useEffect, useRef, useState} from "react";
import GridList from "@material-ui/core/GridList";
import {makeStyles} from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";
import clsx from "clsx";
import {Fade} from "react-slideshow-image";
import Typography from "@material-ui/core/Typography";
import {Container, Divider} from "@material-ui/core";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";
import {getDatabase, onValue, ref} from "firebase/database";

const textContainerWidthPercentage = 30;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        justifyContent: 'space-evenly',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    },
    gridList: {
        width: '100%',
        padding: theme.spacing(0.5),
    },
    cover: {
        width: 151,
        height: 151,
    },
    selected: {
        '& > div': {
            boxShadow: "0px 0px 10px 2px #ff9000",
        }
    },
    textContainer: {
        width: `${textContainerWidthPercentage}%`,
        flexShrink: 1,
        padding: theme.spacing(0, 1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 0),
            width: '100%',
        }
    },
    img: {
        width: `calc(100% - ${textContainerWidthPercentage}%)`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    portrait: {
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

const Photos = ({width}) => {

    const classes = useStyles();
    const slideRef = useRef();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [photos, setPhotos] = useState([]);
    const db = getDatabase();
    const handleClick = index => _ => {
        setSelectedIndex(index);
        slideRef.current.goTo(index);
    };

    const handleChange = (oldIndex, newIndex) => {
        setSelectedIndex(newIndex)
    };

    useEffect(() => {
        onValue(ref(db, "Photos"), snapshot => {
            let photos = [];
            snapshot.forEach(snap => {
                photos.push(snap.val());
            });
            setPhotos(photos);
        })
    }, []);

    return (
        <Container>
            <Fade arrows={false} duration={3000} pauseOnHover={true} onChange={handleChange} autoplay={true}
                  ref={slideRef}>
                {photos.map((photo, index) => (
                    <div className={clsx(classes.root, "each-fade")} key={`image-${index}`}>
                        <div className={classes.textContainer}>
                            <Typography variant="h5">
                                {photo.timestamp}
                            </Typography>
                            <Typography variant="overline">
                                {photo.location}
                            </Typography>
                            <Typography variant="body1">
                                {photo.description}
                            </Typography>
                        </div>
                        <div style={{
                            backgroundImage: `url('${photo.url}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: photo.reposition ? photo.reposition : "center",
                            backgroundSize: 'cover',
                            objectFit: 'cover',
                            height: '50vh'
                        }} className={photo.isPortrait ? classes.portrait : classes.img}/>
                    </div>
                ))}
            </Fade>
            <Divider style={{margin: '20px 0'}}/>
            <GridList cellHeight={isWidthUp('sm', width) ? 160 : 80} className={classes.gridList}
                      cols={isWidthUp('sm', width) ? 5 : 3}>
                {photos.map((photo, index) => (
                        <GridListTile key={photo.url} cols={photo.cols || 1}
                                      className={clsx(selectedIndex === index && classes.selected)}>
                            <img src={photo.url} alt={photo.title} style={{cursor: 'pointer'}}
                                 onClick={handleClick(index)}/>
                        </GridListTile>
                    )
                )}
            </GridList>
        </Container>
    )
};

export default withWidth()(Photos);
