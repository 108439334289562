import React from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../config";
import { Navigate } from "react-router-dom";
import { ROUTE_HOME, ROUTE_LOGIN } from "../routes";
import CircularProgress from "@material-ui/core/CircularProgress";

const Landing = () => {
    const [user, loading, error] = useAuthState(auth);

    if (loading) {
        return <CircularProgress />
    }
    //TODO: Some error handling
    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
            </div>
        );
    }

    if (user) {
        return <Navigate to={ROUTE_HOME}/>
    }

    return <Navigate to={ROUTE_LOGIN}/>
};

export default Landing;
