import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import {db} from "../config";
import {getDatabase, onValue, ref} from "firebase/database";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        justifyContent: 'space-between',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: 'contain',
        backgroundAttachment: 'fixed',
        objectFit: 'fill',
        height: '100%',
        marginRight: 0,
        boxShadow: '0 0 0 30px transparent inset',
        paddingLeft: theme.spacing(10),
    },
    gridContainer: {
        padding: `${theme.spacing(3)}px 0`,
    },
}));

const Quotes = () => {

    const classes = useStyles();
    const [quotes, setQuotes] = useState([]);
    const [imgUrl, setImgUrl] = useState("");
    const db = getDatabase();

    useEffect(() => {
        onValue(ref(db, "Quotes"), snapshot => {
            let quotes = [];
            snapshot.forEach(snap => {
                if (snap.val().isImgConfig) {
                    setImgUrl(snap.val());
                } else {
                    quotes.push(snap.val());
                }
            });
            setQuotes(quotes);
        })
    }, []);

    return (
        <div className={classes.root} style={{
            backgroundImage: `linear-gradient(to right, #fff3bc, #fff3bc 55%, transparent 85%), url('${imgUrl}')`
        }}>
            <Grid container
                  direction="column"
                  spacing={2}
                  className={classes.gridContainer}
            >
                {
                    quotes.map((quote, index) => (
                        <Grid item xs={6} key={`quote-${index}`}>
                            <Slide direction="up" in={true} mountOnEnter unmountOnExit
                                   style={{transitionDelay: `${(index + 1) * 1000}ms`}}
                                   timeout={1000}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography paragraph variant="h3" component="h2">
                                            {`"${quote.cn}"`}
                                        </Typography>
                                        <Typography paragraph variant="h5" component="p" color="textSecondary">
                                            {`"${quote.en}"`}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Slide>
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    )
};

export default Quotes;
