import React from 'react';
import {Container, Typography, Card, Button, CardContent} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ROUTE_HOME} from "../routes";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(4, 0),
    },

    card: {
        '& > *': {
            margin: theme.spacing(2, 0),
        }
    }
}));

const NotFound = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <Card>
                <CardContent className={classes.card}>
                    <Typography variant="h2"> Page not found </Typography>
                    <Typography variant="h5">
                        Opps! The page you are looking for does not exist.
                    </Typography>
                    <Button><Link to={ROUTE_HOME}>Back to home</Link></Button>
                </CardContent>
            </Card>
        </Container>
    )
};

export default NotFound;