import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import withWidth from "@material-ui/core/withWidth";
import {signInWithGoogle} from "../config";
import {ROUTE_HOME} from "../routes";
import { Navigate } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import GoogleLogo from '../img/google_logo.svg';
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        border: 'none',
    },
    container: {
        '& > *': {
            margin: theme.spacing(1, 0),
        }
    },
    buttonGroup: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '& > * ': {
            margin: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        }
    },
    button: {
        margin: theme.spacing(1),
        whiteSpace: 'nowrap',
        width: '100%',
    },
}));

const GoogleIcon = () => (<img width="18px"
                               src={GoogleLogo}
                               alt="google-icon"/>);

const LoginModal = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleSigninGoogle = () => {
        signInWithGoogle()
            .then(result => {
                setOpen(false);
                return <Navigate to={ROUTE_HOME}/>
            })
            .catch(error => {
                console.log('Unable to log in. Error: ', error);
            })
    };

    return (
        <div>
            <Modal
                aria-labelledby="login-title"
                aria-describedby="login-description"
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <Container className={classes.container}>
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                    startIcon={<GoogleIcon/>}
                                    fullWidth
                                    size='large'
                                    onClick={handleSigninGoogle}
                                >
                                    Sign in with Google
                                </Button>
                        </Container>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default withWidth()(LoginModal);
