import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Fade} from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
// import {db} from "../config";
import { getDatabase, ref, onValue } from "firebase/database";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
    },
    title: {
        color: "white",
        position: 'absolute',
        bottom: 0,
        zIndex: 2,
        right: theme.spacing(2),
        fontFamily: '"Sacramento", cursive',
        fontSize: 100,
        lineHeight: 'unset',
    },
    fadeIn: {
        display: 'inline-block',
        textTransform: 'none',
        margin: '0 20px',
        opacity: '0.0',
        animation: 'fadeIn ease 10s'
    }
}));

const HomePhotoGallery = () => {
    const classes = useStyles();
    const [photoList, setPhotoList] = useState([]);
    const db = getDatabase();
    useEffect(() => {
            onValue(ref(db,"Home"), snapshot => {
                let photos = [];
                snapshot.forEach(snap => {
                    photos.push(snap.val());
                });
                setPhotoList(photos);
            })
    }, []);

    return (
        <div className={classes.root}>
            <Fade arrows={false} duration={3000} pauseOnHover={false}>
                {photoList.map((image, index) => (
                    <div className={clsx(classes.root, "each-fade")} key={`image-${index}`}>
                        <div style={{
                            backgroundImage: `url('${image.url}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: image.reposition ? image.reposition : "center",
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                            width: '100vw',
                            height: '100vh'
                        }} />
                    </div>
                ))
                }
            </Fade>
            <Grow in={true} timeout={3000}>
                <Typography variant="h1" component="h1" className={classes.title}>
                    Forever Michael
                </Typography>
            </Grow>
        </div>
    )
};

export default HomePhotoGallery;
