import React, {useEffect, useState} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import FlowerOptionsGrid from "./FlowerOptionsGrid";
import {Alert} from "./Alert";
import {getDatabase, onValue, ref, set, increment} from "firebase/database";

const GiftFlowers = () => {
    const [flowerCounts, setFlowerCounts] = useState([]);
    const [displaySnackbar, setDisplaySnackbar] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shouldRefreshCounts, setShouldRefreshCounts] = useState(true);
    const db = getDatabase();

    const handleClickSend = id => () => {
        const updateCount = set(ref(db, `Flowers/${id}/count`), increment(1));
        const updateTime =   set(ref(db, `Flowers/${id}/lastUpdatedTimestamp`), Date.now());

        setIsSubmitting(true);
        Promise.all([updateCount, updateTime])
            .then(_ => {
                setDisplaySnackbar(true);
                setIsSuccessful(true);
                setShouldRefreshCounts(true);
            })
            .catch(error => {
                console.log(error.message);
                setIsSuccessful(false);
            });
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (shouldRefreshCounts) {
            onValue(ref(db, "Flowers"), snapshot => {
                let updatedCounts = [];
                snapshot.forEach(snap => {
                    updatedCounts.push(snap.val());
                });
                setFlowerCounts(updatedCounts);
            })
            setShouldRefreshCounts(false);
        }

    }, [flowerCounts, shouldRefreshCounts]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDisplaySnackbar(false);
    };

    return (
        <>
            <FlowerOptionsGrid flowers={flowerCounts} onClick={handleClickSend} isSubmitting={isSubmitting}/>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={displaySnackbar}
                      autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={isSuccessful ? "success" : "error"}>
                    {isSuccessful ? "You have successfully gifted a flower" : "An error occurred. Please try again later."}
                </Alert>
            </Snackbar>
        </>
    )
};

export default GiftFlowers;
