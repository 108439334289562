import { initializeApp } from 'firebase/app';
import {getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup} from "firebase/auth";
import { getDatabase } from 'firebase/database';

// console.log("process.env.REACT_APP_API_KEY: ", process.env.REACT_APP_API_KEY)
const config = {
    // apiKey: process.env.REACT_APP_API_KEY,
    // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_DATABASE_URL,
    // projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    // appId:  process.env.REACT_APP_ID,
    // measurementId:  process.env.REACT_APP_MEASUREMENT_ID,
    apiKey: "AIzaSyDgFxVkdEAyxMuSkiqvwdiwuZOzorLDwOE",
    authDomain: "app-michael-lin-memorial.firebaseapp.com",
    databaseURL: "https://app-michael-lin-memorial.firebaseio.com",
    projectId: "app-michael-lin-memorial",
    storageBucket: "app-michael-lin-memorial.appspot.com",
    messagingSenderId: "173318263945",
    appId: "1:173318263945:web:3b646b6007c75703812258",
    measurementId: "G-S8W1SJ8VBH"
};

const app = initializeApp(config);
export const auth = getAuth(app);
export const db = getDatabase(app);

const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export const signInWithGoogle = () => {
    return signInWithPopup(auth, googleAuthProvider);
}

export const signInWithFacebook = () => {
    return signInWithPopup(auth, facebookAuthProvider);
}

export const singOut = () => {
    return auth.signOut();
}

export const createUserWithEmailAndPassword = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
};

